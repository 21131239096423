var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "authentication-layout",
    [
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/icon-mail-sent.png"),
              alt: "Email enviado",
            },
          }),
          _c("h2", { staticClass: "title" }, [
            _vm._v("Seu cadastro foi realizado com sucesso."),
          ]),
        ]
      ),
      _c("template", { slot: "form-content" }, [
        _c("p", [
          _vm._v("\n      Acesse seu email, "),
          _c("strong", [_vm._v(_vm._s(_vm.email))]),
          _vm._v(
            " e\n      ative sua conta para criar MTR's, gerar relatórios de\n      recebimento e certificados de destinação final (CDF).\n    "
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            {
              staticClass: "link",
              attrs: { flat: "", small: "", color: "primary", to: "/" },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-arrow-left")]),
              _c("span", { staticClass: "text" }, [
                _vm._v("Voltar para a página inicial"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }