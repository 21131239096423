<template>
  <authentication-layout>
    <header slot="form-header" class="header">
      <img src="@/assets/icon-mail-sent.png" alt="Email enviado" />
      <h2 class="title">Seu cadastro foi realizado com sucesso.</h2>
    </header>

    <template slot="form-content">
      <p>
        Acesse seu email, <strong>{{email}}</strong> e
        ative sua conta para criar MTR's, gerar relatórios de
        recebimento e certificados de destinação final (CDF).
      </p>
    </template>

    <template slot="navigation">
      <v-btn flat small color="primary" class="link" to="/">
        <v-icon left>mdi-arrow-left</v-icon>
        <span class="text">Voltar para a página inicial</span>
      </v-btn>
    </template>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';

export default {
  name: 'RegisterSuccess',
  components: {
    AuthenticationLayout,
  },
};
</script>
